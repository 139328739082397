import React from "react";
import "./loading.css";
import loader from "../assets/loader.gif";
interface LoadingProps {}

export const Loading: React.FC<LoadingProps> = ({}) => {
  return (
    <div className="Main">
      <div className="loadingContainer">
        <span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div className="base">
          <span></span>
          <div className="face"></div>
        </div>
      </div>
      <div className="longfazers">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export const EdgeLoader = ({}) => {
  return (
    <div className="MainLoader">
      <img src={loader} className="loaderGif"></img>
    </div>
  );
};
