import React, { useState, createContext } from "react";

type Props = {};

export type CartItemType = {
  id: number;
  description: string;
  image: string;
  price: number;
  title: string;
  amount: number;
  rules: string;
  details: [
    {
      name: string;
      number: string;
    },
    {
      name: string;
      number: string;
    }
  ];
};

type ICartContext = [
  CartItemType[],
  React.Dispatch<React.SetStateAction<CartItemType[]>>
];

export const CartContext = createContext<ICartContext>([[], () => null]);

export const CartProvider: React.FC<Props> = (props) => {
  const [cartItems, setCartItems] = useState<CartItemType[]>(
    [] as CartItemType[]
  );

  return (
    <CartContext.Provider value={[cartItems, setCartItems]}>
      {props.children}
    </CartContext.Provider>
  );
};
