import React from "react";
import "./App.css";
import Loadable from "react-loadable";
import pMinDelay from "p-min-delay";
import { Loading } from "./components/loading/Loading";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { CartProvider } from "./components/CartContext/CartContext";

export const EventsinClubs = Loadable({
  loader: () =>
    pMinDelay(import("./components/EventsinClubs/EventsinClubs"), 500),
  loading: Loading,
});
export const Cart = Loadable({
  loader: () => pMinDelay(import("./components/Cart/Cart"), 500),
  loading: Loading,
});

export const EDGEEvents = Loadable({
  loader: () => pMinDelay(import("./components/events@EDGE/Events"), 500),
  loading: Loading,
});

export const SubClubs = Loadable({
  loader: () => pMinDelay(import("./components/sub-clubs/SubClubs"), 500),
  loading: Loading,
});

export const RegsitrationDetails = Loadable({
  loader: () => pMinDelay(import("./components/Register/Register"), 500),
  loading: Loading,
});

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <BrowserRouter>
      <Switch>
        <CartProvider>
          <Route exact path="/">
            <Redirect to={{ pathname: "/intra/events" }} />
          </Route>
          <Route exact path="/intra/cart" component={Cart} />
          <Route path="/intra/events/:id" component={EventsinClubs} />
          <Route exact path="/intra/events" component={EDGEEvents} />
          <Route exact path="/intra/clubs" component={SubClubs} />
          <Route exact path="/intra/details" component={RegsitrationDetails} />
        </CartProvider>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
